import React, { Component } from "react";
import { connect } from "react-redux";
import tinycolor from "tinycolor2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { toggleChat } from "../../actions/app";
import { ShakeButton } from "./shakeAnimation-desk";
import { BlinkButton } from "./blinkAnimation-desk";
import "./buttonAnimation.scss";

class ChatButtonDesktop extends Component {
  iconSize = "lg";
  buttonAnimationClass = "";
  slideBarSide;
  addClasse;
  removeClasse;
  removeSetTimeOut;

  getButtonCustomIcon = (floatingButtonIconUrl) => {
    return {
      backgroundImage: `url(${floatingButtonIconUrl})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  };

  getButtonShape = (shape) => {
    switch (shape) {
      case "roundBorder":
        return {
          borderRadius: `${this.props.config.desktop.buttonBorderRadiusPx}px`,
          height: `${this.props.config.desktop.buttonHeight}px`,
          width: `${this.props.config.desktop.buttonWidth}px`,
        };

      case "circle":
        return {
          borderRadius: "50%",
          height: `${this.props.config.desktop.buttonHeight}px`,
          width: `${this.props.config.desktop.buttonWidth}px`,
        };
      // square
      default:
        return {
          height: `${this.props.config.desktop.buttonHeight}px`,
          width: `${this.props.config.desktop.buttonWidth}px`,
        };
    }
  };

  getSlideContainerShape = (shape) => {
    switch (shape) {
      case "roundBorder":
        return {
          borderRadius: `${this.props.config.desktop.buttonBorderRadiusPx}px`,
          height: `${this.props.config.desktop.buttonHeight}px`,
          width: `${this.props.config.desktop.buttonWidth}px`,
        };

      case "circle":
        return {
          borderRadius: "200px",
          height: `${this.props.config.desktop.buttonHeight}px`,
          width: `${this.props.config.desktop.buttonWidth}px`,
        };
      // square
      default:
        return {
          height: `${this.props.config.desktop.buttonHeight}px`,
          width: `${this.props.config.desktop.buttonWidth}px`,
        };
    }
  };

  getButtonPosition = (position) => {
    switch (position) {
      case "sideviewRight":
        this.slideBarSide = { right: 0 };
        return {
          pointerEvents: "none",
          position: "fixed",
          bottom: "50%",
          right: 0,
        };
      case "sideviewLeft":
        this.slideBarSide = { left: 0 };
        return {
          pointerEvents: "none",
          position: "fixed",
          bottom: "50%",
          left: 0,
        };
      case "bottonLeft":
        this.slideBarSide = { left: 0 };
        return {
          pointerEvents: "none",
          position: "fixed",
          bottom: 0,
          left: 0,
        };
      // bottonRight
      default:
        this.slideBarSide = { right: 0 };
        return {
          pointerEvents: "none",
          position: "fixed",
          bottom: 0,
          right: 0,
        };
    }
  };

  getSlideAnimation = () => {
    const initTime = this.props.config.desktop.buttonAnimationInitTime
      ? this.props.config.desktop.buttonAnimationInitTime
      : "0";
    this.buttonAnimationClass = "omni-button-slide";
    return {
      animationName: "slide",
      animationDuration: "4s",
      animationTimingFunction: "linear",
      animationIterationCount: 1,
      animationDelay: `${initTime}s`,
    };
  };

  getButtonStyle = () => {
    const customIcon = this.props.config.desktop.floatingButtonIconUrl
      ? this.getButtonCustomIcon(
          this.props.config.desktop.floatingButtonIconUrl
        )
      : "";
    const shape = this.props.config.desktop.buttonShape
      ? this.props.config.desktop.buttonShape.trim()
      : "";
    const dktStyle = {
      pointerEvents: "auto",
      cursor: "pointer",
      backgroundColor: tinycolor(
        this.props.config.desktop.buttonBackgroundColor
      ),
      border: `2px solid ${tinycolor(this.props.config.desktop.buttonBorderColor)}`,
      marginRight: `${this.props.config.desktop.buttonMarginRight}px`,
      marginLeft: `${this.props.config.desktop.buttonMarginLeft}px`,
      marginBottom: `${this.props.config.desktop.buttonMarginBottom}px`,
      ...this.getButtonShape(shape),
      ...customIcon,
    };
    return dktStyle;
  };

  getButtonSlideStyle = () => {
    const customIcon = this.props.config.desktop.floatingButtonIconUrl
      ? this.getButtonCustomIcon(
          this.props.config.desktop.floatingButtonIconUrl
        )
      : "";
    const shape = this.props.config.desktop.buttonShape
      ? this.props.config.desktop.buttonShape.trim()
      : "";
    return {
      borderBox: '',
      cursor: "pointer",
      backgroundColor: tinycolor(
        this.props.config.desktop.buttonBackgroundColor
      ),
      border: `2px solid ${tinycolor(this.props.config.desktop.buttonBorderColor)}`,
      display: "block",
      position: "absolute",
      top: 0,
      ...this.slideBarSide,
      ...this.getButtonShape(shape),
      ...customIcon,
    };
  };

  getSliderContainer = () => {
    const animation = this.props.config.buttonAnimation
      ? this.getSlideAnimation()
      : "";
    const shape = this.props.config.desktop.buttonShape
      ? this.props.config.desktop.buttonShape.trim()
      : "";
    const sliderContainer = {
      pointerEvents: "auto",
      cursor: "pointer",
      backgroundColor: tinycolor(
        this.props.config.desktop.buttonBackgroundColor
      ),
      overflow: "hidden",
      position: "relative",
      marginRight: `${this.props.config.desktop.buttonMarginRight}px`,
      marginLeft: `${this.props.config.desktop.buttonMarginLeft}px`,
      marginBottom: `${this.props.config.desktop.buttonMarginBottom}px`,
      lineHeight: `${this.props.config.desktop.buttonHeight}px`,
      letterSpacing: 0.4,
      fontStyle: "normal",
      fontweight: "bold",
      fontSize: 16,
      color: "white",
      ...animation,
      ...this.getSlideContainerShape(shape),
    };
    return sliderContainer;
  };

  handleClick = (e) => {
    clearInterval(this.addClasse);
    clearInterval(this.removeClasse);
    clearInterval(this.removeSetTimeOut);
    this.props.open();
  };

  getSpanTextStyle = () => {
    if (
      this.props.config.desktop.position === "sideviewLeft" ||
      this.props.config.desktop.position === "bottonLeft"
    ) {
      const marginLeft = `${
        parseInt(this.props.config.desktop.buttonWidth) + 2
      }`;
      return {
        position: "absolute",
        paddingLeft: "10px",
        paddingRight: "10px",
        marginLeft: `${marginLeft.toString()}px`,
        whiteSpace: "nowrap",
        width: "80%",
        overflow: " hidden",
        textOverflow: "ellipsis",
      };
    } else {
      return {
        position: "absolute",
        paddingLeft: "10px",
        paddingRight: "10px",
        whiteSpace: "nowrap",
        width: "80%",
        overflow: " hidden",
        textOverflow: "ellipsis",
      };
    }
  };

  renderSlideButton = () => {
    return (
      <div
        id="omni-box-slider"
        style={this.getSliderContainer()}
        onClick={this.handleClick}
        className={`omni-container-slider ${this.buttonAnimationClass}`}
      >
        <span for="omni-show-chat" style={this.getSpanTextStyle()}>
          {this.props.config.desktop.buttonAnimationText}
        </span>
        <div>
          <button
            id="omni-show-chat"
            title="Chat show Omni Button"
            onClick={this.handleClick}
            style={this.getButtonSlideStyle()}
          >
            {this.props.config.desktop.floatingButtonIconUrl ? (
              <></>
            ) : (
              <FontAwesomeIcon
                icon={faComments}
                size={this.iconSize}
                className="chat-icon"
              />
            )}
          </button>
        </div>
      </div>
    );
  };

  renderShakeButton = () => {
    this.animateRepetition();
    return (
      <ShakeButton
        config={this.props.config}
        id="omni-show-chat"
        onClick={this.handleClick}
        style={this.getButtonStyle()}
      >
        {this.props.config.desktop.floatingButtonIconUrl ? (
          <></>
        ) : (
          <FontAwesomeIcon
            icon={faComments}
            size={this.iconSize}
            className="chat-icon"
          />
        )}
      </ShakeButton>
    );
  };

  renderBlinkButton = () => {
    this.animateRepetition();
    return (
      <BlinkButton
        config={this.props.config}
        id="omni-show-chat"
        onClick={this.handleClick}
        style={this.getButtonStyle()}
      >
        {this.props.config.desktop.floatingButtonIconUrl ? (
          <></>
        ) : (
          <FontAwesomeIcon
            icon={faComments}
            size={this.iconSize}
            className="chat-icon"
          />
        )}
      </BlinkButton>
    );
  };

  renderButton = () => {
    return (
      <button
        config={this.props.config}
        id="omni-show-chat"
        onClick={this.handleClick}
        style={this.getButtonStyle()}
      >
        {this.props.config.desktop.floatingButtonIconUrl ? (
          <></>
        ) : (
          <FontAwesomeIcon
            icon={faComments}
            size={this.iconSize}
            className="chat-icon"
          />
        )}
      </button>
    );
  };

  animateRepetition = () => {
    if (this.props.config.desktop.buttonAnimationRepetitionEnable) {
      let init =
        parseInt(this.props.config.desktop.buttonAnimationInitTime) * 1000;
      let rept =
        parseInt(this.props.config.desktop.buttonAnimationRepetitionInterval) *
        1000;
      let animationClass;
      let timer = init + rept + 50;
      let addTime = 0;
      let deletetime = 0;
      this.removeSetTimeOut = setTimeout(() => {
        animationClass = document
          .getElementById("omni-show-chat")
          .classList.item(1);
        document
          .getElementById("omni-show-chat")
          .classList.remove(animationClass);

        addTime = rept + 100;
        this.addClasse = setInterval(() => {
          document
            .getElementById("omni-show-chat")
            .classList.add(animationClass);
        }, addTime);

        deletetime = addTime + rept;
        this.removeClasse = setInterval(() => {
          animationClass = document
            .getElementById("omni-show-chat")
            .classList.item(1);
          document
            .getElementById("omni-show-chat")
            .classList.remove(animationClass);
        }, deletetime);
      }, timer);
    }
  };

  render() {
    return (
      <div
        id="omni-hide-chat-button"
        style={this.getButtonPosition(this.props.config.desktop.position)}
      >
        {this.props.config.buttonAnimation ? this.props.config.desktop.buttonAnimationType === "slide"
          ? this.renderSlideButton()
          : this.props.config.desktop.buttonAnimationType === "shake"
          ? this.renderShakeButton()
          : this.renderBlinkButton() : this.renderButton()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
  open: () => dispatch(toggleChat(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatButtonDesktop);
