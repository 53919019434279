import styled, { keyframes, css } from "styled-components";
// import tinycolor from "tinycolor2";

const blink = (config) => keyframes`
   8% {
      opacity: 0;
    }
    16% {
      opacity: 100;
    }
    25% {
      opacity: 0;
    }
    33% {
      opacity: 100;
    }
  }
`;

const repetitionLoop = (config) => {
  const duration = config.config.desktop.buttonAnimationRepetitionEnable
    ? "infinite"
    : 1;
  return duration;
};

export const BlinkButton = styled.button`
  animation: ${(config) =>
    config.config.buttonAnimation
      ? css`
          ${blink(config)} ${4}s ${config.config.desktop
            .buttonAnimationInitTime}s ${repetitionLoop(config)}
        `
      : null};
`;

// export const BlinkButton = styled.button`
//   cursor: pointer;
//   background-color: ${(config) =>
//     tinycolor(config.config.desktop.buttonBackgroundColor)};
//   border-color: ${(config) =>
//     tinycolor(config.config.desktop.buttonBorderColor)};
//   margin-right: ${(config) => config.config.desktop.buttonMarginRight}px;
//   margin-left: ${(config) => config.config.desktop.buttonMarginLeft}px;
//   margin-bottom: ${(config) => config.config.desktop.buttonMarginBottom}px;
//   animation: ${(config) =>
//     config.config.buttonAnimation
//       ? css`
//           ${blink(config)} ${4}s ${config.config.desktop.buttonAnimationInitTime}s ${1}
//         `
//       : null};
// `;
