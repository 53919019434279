import bowser from "bowser/bundled";

class UserInfo {

  static platform = bowser.getParser(window.navigator.userAgent).parsedResult;

  static getInfo() {
    const isMobile = this.platform.platform.type !== "desktop";

    return {
      platform: this.platform.os.name,
      browser: this.platform.browser.name,
      version: this.platform.browser.version,
      isMobile,
    };
  }

}

export default UserInfo;