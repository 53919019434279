import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import tinycolor from 'tinycolor2';
import ButtonPrint from '../buttonPrint/buttonPrint'

import { toggleModal } from '../../actions/app';

import Rate from '../rate/rate';

import './modal.scss';

class Modal extends PureComponent {

  state = {
    type: '',
    disabled: true,
  }

  constructor(props) {
    super(props);
    this.getModal = this.getModal.bind(this);
    this.getLastMessageTime = this.getLastMessageTime.bind(this);
  }

  handleClick = (type, e) => {
    this.setState({
      ...this.state,
      disabled: false,
    })
  }

  handleClose = e => {
    this.props.toggleModal(false, '');
    this.props.modal.onClose();
    this.setState({
      ...this.state,
      disabled: true,
    });
  }

  handleBack = e => {
    this.props.toggleModal(false, '');
    this.setState({
      ...this.state,
      disabled: true,
    })
  }

  handleConfirm = e => {
    const qtdFields = this.props.config.conversationRatingLabels.length;
    const conversationId = this.props.messages[1] ? this.props.messages[1].context.conversation_id : false;
    const score = document.querySelector('input:checked').value;

    const startMessage = new Date(this.props.messages[0].date);
    const endMessage = new Date(this.getLastMessageTime());

    const form = {
      conversation_id: conversationId,
      startMessage,
      endMessage,
    }

    this.props.modal.onConfirm(form, qtdFields, score);
    this.handleClose();
  }

  getLastMessageTime() {
    let date = '';

    const size = this.props.messages.length;

    for (let i = size - 1; i >= 0; i--) {
      const temp = this.props.messages[i].date;
      if (temp) {
        date = temp;
        break;
      }
    }

    return date;
  }

  setBg = color => e => {
    if (!color) {
      e.currentTarget.style.backgroundColor = 'white';
      e.currentTarget.style.color = 'black';
      return false;
    }
    const color2 = color;

    let fontColor = color2.darken(20);
    fontColor = fontColor.toRgbString();
    let backColor = color2.setAlpha(0.13);
    backColor = color2.toRgbString();

    e.currentTarget.style.backgroundColor = this.props.theme === 'light' ? backColor : 'lightgray';
    e.currentTarget.style.color = this.props.theme === 'light' ? fontColor : 'black';;
  }

  getModal() {
    if (this.props.config.headerColor) {
      document.documentElement.style.setProperty('--header-color', this.props.config.headerColor);
    }
    
    const colorHeader = getComputedStyle(document.documentElement).getPropertyValue('--header-color');
    const header = tinycolor(colorHeader);

    const color = this.props.theme === 'light' ? header.getLuminance() > 0.5 ? "#333333" : "black" : 'black';    

    return (
      <div className='Modal-container' onClick={this.handleClose}>
        <div className='box' onClick={e => e.stopPropagation()}>
          <p className='text' style={{ whiteSpace: "pre-line" }}>
            {this.props.modal.message}
          </p>
          <Rate inline onClick={this.handleClick} />
          <ButtonPrint config={this.props.config}/>
          <div className='action-buttons'>
            <button 
              title='Sair da janela de avaliação' 
              className='cancel' 
              onClick={this.handleClose}
            >
              Sair
            </button>
            <button
              title='Voltar para o chat'
              className='back'
              style={{ color: "black" }}
              onClick={this.handleBack}
              onMouseOver={this.setBg(header)}
              onMouseOut={this.setBg("")}
            >
              Voltar para o Chat
            </button>
            <button
              title='Enviar avaliação'
              className='send'
              disabled={this.state.disabled}
              style={{ color: color }}
              onClick={this.handleConfirm}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.modal.status ? this.getModal() : false;
  }
}

const mapStateToProps = state => ({
  config: state.config,
  modal: state.app.modal,
  messages: state.messages,
  theme: state.theme
});

const mapDispatchToProps = dispatch => ({
  toggleModal: (toggle, message) => dispatch(toggleModal(toggle, message))
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);