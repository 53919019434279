import styled, { keyframes, css } from "styled-components";
// import tinycolor from "tinycolor2";

const shake = (config) => keyframes`
    8% {
       transform: translateX(-5px);
     }
    16% {
       transform: translateX(5px);
     }
    25% {
       transform: translateX(-5px);
     }
    33% {
       transform: translateX(0);
     }
`;

const repetitionLoop = (config) => {
  const duration = config.config.mobile.buttonAnimationRepetitionEnable
    ? "infinite"
    : 1;
  return duration;
};

export const ShakeButton = styled.button`
  animation: ${(config) =>
    config.config.buttonAnimation
      ? css`
          ${shake(config)} ${2}s ${config.config.mobile
            .buttonAnimationInitTime}s ${repetitionLoop(config)}
        `
      : null};
`;

// export const ShakeButton = styled.button`
//   cursor: pointer;
//   background-color: ${(config) =>
//     tinycolor(config.config.mobile.buttonBackgroundColor)};
//   border-color: ${(config) =>
//     tinycolor(config.config.mobile.buttonBorderColor)};
//   margin-right: ${(config) => config.config.mobile.buttonMarginRight}px;
//   margin-left: ${(config) => config.config.mobile.buttonMarginLeft}px;
//   margin-bottom: ${(config) => config.config.mobile.buttonMarginBottom}px;
//   animation: ${(config) =>
//     config.config.buttonAnimation
//       ? css`
//           ${shake(config)} ${2}s ${config.config.mobile.buttonAnimationInitTime}s alternate ${duration(config)}
//         `
//       : null};
// `;
