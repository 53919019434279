export default class AuthenticationService {
    properties = [
        { name: 'dataUltimoAcesso', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'origemLogin', type: 'string' },
        { name: 'roles', type: 'string' },
        { name: 'sub', type: 'number' },
        { name: 'token', type: 'string' },
    ]

    get dataLogged() {
        const data = localStorage.getItem('currentUser')
        if (!data) return {}
        try {
            const payload = JSON.parse(data)
            return payload
        } catch (e) {
            return console.log(` error dataLogged => ${e}`)
        } 
    }
    get logged() {
        const data = localStorage.getItem('currentUser')
        if (!data) return false
        try {
            const payload = JSON.parse(data)
            return this.validate(payload)
        } catch (e) {
            return false
        }
    }

    get user() {
        const data = localStorage.getItem('currentUser')
        if (!data) return null
        try {
            const payload = JSON.parse(data)
            if (this.validate(payload)) {
                return payload
            }
            return null 
        } catch (e) {
            return null 
        }
    }

    validate(payload) {
        var data = this.properties && this.properties.map((prop) => {
            if (payload.hasOwnProperty(prop.name) && payload[prop.name]) {
                return payload[prop.name]
            }
        if (data.length === Object.keys(payload).length) {
            return true
        }
        return false
        })
    }
    static dataLogged() {
        const data = localStorage.getItem('currentUser')
        if (!data) return {}
        try {
            const payload = JSON.parse(data)
            return payload
        } catch (e) {
            return console.log(` error dataLogged => ${e}`)
        } 
    }
}
