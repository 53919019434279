import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { sendEvaluation, setCounter } from '../../actions/app';
import { sendMessage } from "../../actions/message";


import "./rate.scss";


class Rate extends PureComponent {

  types = {
    stars: () => this.renderStars(),
    string: () => this.renderString(),
  }

  counter = false;

  state = {
    hovered: '',
    selected: '',
    disabled: false,
  }

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
    this.handleClickStar = this.handleClickStar.bind(this);
  }

  handleDisable(isHumanService) {
    this.props.sendMessage({
      message: this.props.config.intentionalTerminationMessage === '' ? "Seu atendimento foi finalizado." : this.props.config.intentionalTerminationMessage,
      operator: isHumanService ? 'INSS' : 'app',
    }, isHumanService ? 'operator' : 'app');
    this.setState({
      ...this.state,
      disabled: true,
    });
  }

  handleClick = type => e => {
    const isHumanService =
      this.props.messages &&
      this.props.messages.length > 1 &&
      this.props.messages[this.props.messages.length - 1].operator === 'INSS';

    if (type === "stars") this.handleClickStar(e);

    if (this.props.onClick) {
      this.props.onClick(type, e);
      return false;
    }

    if (this.state.disabled) return false;
    // TODO: Reavaliar botRateAcknowledgment vindo do Config (Falar com Ivan para maiores detalhes)
    if (!this.counter) {
      this.counter = true;
      this.props.sendMessage({
        message: isHumanService ? this.props.config.humanEvaluationAcknowledgment : this.props.config.botRateAcknowledgment,
        operator: 'INSS',
        actions: {
          interaction: true
        }
      }, isHumanService ? 'operator' : 'app');
      const counter = setTimeout(() => {
        this.handleDisable(isHumanService);
      }, 5000);
      this.props.setCounter(counter);
    }

    const qtdFields = this.props.config.conversationRatingLabels.length;
    const conversationId = this.props.messages[1] ? this.props.messages[1].context.conversation_id : false;
    const score = e.currentTarget.parentNode.querySelector('input').value;

    const form = {
      conversation_id: conversationId,
    }
    this.props.sendEvaluation(form, qtdFields, score);
  }

  handleOver = e => {
    if (this.state.disabled) return false;
    this.setState({
      ...this.state,
      hovered: e.currentTarget.htmlFor
    });
  }

  handleOut = e => {
    this.setState({
      ...this.state,
      hovered: ''
    })
  }

  handleClickStar = e => {
    this.setState({
      ...this.state,
      selected: e.currentTarget.htmlFor
    });
  }

  getLastMessageTime() {
    let date = '';

    const size = this.props.messages.length;

    for (let i = size - 1; i >= 0; i--) {
      const temp = this.props.messages[i].date;
      if (temp) {
        date = temp;
        break;
      }
    }

    return date;
  }

  getRateType() {
    const rateType = this.props.config.conversationRatingType;

    if (rateType !== 'string' && rateType !== 'stars') return false;

    return this.types[rateType]();
  }

  renderStars() {
    const inputs = [];
    const fieldsQtd = this.props.config.conversationRatingLabels.length;

    let filledHover = true;
    let filledClick = true;

    for (let i = 0; i < fieldsQtd; i++) {
      inputs.push(i);
    }

    return (
      <form className='stars' ref={el => this.form = el}>
        {
          inputs.map((value, index) => {
            const id = `radio-star-${index}`;
            let inputClass = `radio-star-${index}`;
            let labelClass = '';

            if (this.state.hovered) {
              if (filledHover) inputClass = `${inputClass} filled`;
              if (this.state.hovered === id) filledHover = false;
            }

            if (this.state.selected) {
              if (filledClick) inputClass = `${inputClass} filled`;
              if (this.state.selected === id) filledClick = false;
            }

            if (this.state.disabled) {
              labelClass = 'disabled';
            }

            return (
              <div className='star-container' key={index} >
                <input id={id} name='stars' type='radio' value={value} className={inputClass} disabled={this.state.disabled} />
                <label
                  className={labelClass}
                  htmlFor={id}
                  onMouseOver={this.handleOver}
                  onMouseOut={this.handleOut}
                  onClick={this.handleClick('stars')}
                ></label>
              </div>
            )
          })
        }
      </form>
    );
  }

  renderString() {
    let formClass = '';

    if (this.props.inline) formClass = 'inline';

    return (
      <form ref={el => this.form = el} className={formClass}>
        {
          this.props.config.serviceEvaluation.labels.map((value, index) => {
            const id = `radio-${index}`;
            return (
              <div className='input-container' key={index}>
                <input id={id} type='radio' className='radio-string' name='rate' value={index} disabled={this.state.disabled} />
                <label htmlFor={id} onClick={this.handleClick('string')}>{value}</label>
              </div>
            );
          })
        }
      </form>
    );
  }

  render() {
    let rateContainerClass = "rate-container";

    if (this.state.disabled) rateContainerClass = `${rateContainerClass} disabled-radios`;

    return (
      <div className={rateContainerClass}>
        {this.getRateType()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
  messages: state.messages,
});

const mapDispatchToProps = dispatch => ({
  setCounter: counter => dispatch(setCounter(counter)),
  sendMessage: (message, from) => dispatch(sendMessage(message, from)),
  sendEvaluation: (form, qtdFields, score) => dispatch(sendEvaluation(form, qtdFields, score)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rate);