export const TOGGLE_CHAT = "TOGGLE_CHAT";
export const TOGGLE_MINIMIZE = "TOGGLE_MINIMIZE";
export const TOGGLE_OPTIONS = "TOGGLE_OPTIONS";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_WEBSOCKET = "TOGGLE_WEBSOCKET";
export const STORE_CONFIG = "STORE_CONFIG";
export const INIT_CONVERSATION = "INIT_CONVERSATION";
export const SET_CONVERSATION = "SET_CONVERSATION";
export const CLOSE_CONVERSATION = "CLOSE_CONVERSATION";
export const CLEAR_CONVERSATION = "CLEAR_CONVERSATION";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const SAVE_CONVERSATION = "SAVE_CONVERSATION";
export const TOGGLE_SPEECH_TO_TEXT = "TOGGLE_SPEECH_TO_TEXT";
export const TOGGLE_TEXT_TO_SPEECH = "TOGGLE_TEXT_TO_SPEECH";
export const LOADED_TEXT_TO_SPEECH_SOUND = "LOADED_TEXT_TO_SPEECH_SOUND";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const COUNTER = "COUNTER";
export const TOGGLE_ATTACH_LOADING = "TOGGLE_ATTACH_LOADING";
export const TOGGLE_ATTACH_AVAILABLE = "TOGGLE_ATTACH_AVAILABLE";
export const TOGGLE_CLIENT_INPUT = "TOGGLE_CLIENT_INPUT";
export const TOGGLE_SEND_MSG_TO_OPERATOR = "TOGGLE_SEND_MSG_TO_OPERATOR";
export const TOGGLE_MSG_SOUND = "TOGGLE_MSG_SOUND";
export const CHANGE_TO_LIGHT = "CHANGE_TO_LIGHT";
export const CHANGE_TO_DARK = "CHANGE_TO_DARK";

