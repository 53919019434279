/**
 * Clear these itens after chat end
 * 
 * "last-activity"
 * "action-context"
 * "session-inputActive"
 * "isCustomerSendMessageToOperator"
 * "session-inputActive"
 * "session-unread-messages"
 * "preview"
 * "userName"
 * "cpf"
 * "user_id"
 * "user_sysId"
 * "user_token
 * "action-context"
 * "session-messages"
 * "session-config"
 */
export default function clearLocalStorage() {
    const itens = ["last-activity", "action-context", "session-inputActive", "isCustomerSendMessageToOperator", "session-inputActive", "session-unread-messages", "preview", "userName", "cpf", "user_id", "user_sysId", "user_token", "action-context", "session-messages", "session-config"]
    itens && itens.map(item => localStorage.removeItem(item))
}