import AppServices from "./appservices";

class SpeechService extends AppServices {

  HEADERS = new Headers()

  toBlob(audioChunks) {
    return new Blob(audioChunks, { 'type' : 'audio/wav' });
  }

  appendFiles(file) {
    const fd = new FormData();
    fd.append(`speech`, file);
    return fd;
  }

  toText(audio, signal) {
    const blob  = this.toBlob(audio);
    const formData = this.appendFiles(blob);
    return this.request("/speech-to-text", "POST", formData, null, { signal });
  }

  toAudio(text) {
    return this.request("/text-to-speech", "POST", { text });
  }
}

export default SpeechService;