import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { loadConfig } from "../actions/config";
import ChatButtonDesktop from "../components/chat/chat-button-desktop";
import ChatButtonMobile from "../components/chat/chat-button-mobile";
import ChatSession from "../helpers/chatSession";
import { toggleChat, initConversation, toggleWebsocket } from "../actions/app";
import Modal from "../components/modal/modal";
import UserInfo from "../services/user-info";
import clearLocalStorage from "../helpers/clearLocalstorage";

const Chat = React.lazy(() => import('../components/chat/chat'));

class AppContainer extends Component {
  userInfo = UserInfo.getInfo();

  constructor(props) {
    super(props);
    this.props.loadConfig();
  }

  componentDidMount() {
    /*TODO: see bug 3157 @martini 2020-01-14
      if (ChatSession.checkSession() || window.location.href.includes("?"))
      */
    // this.props.loadConfig();
    this.setupApp();
  }

  setupApp() {
    const interval = setInterval(() => {
      if (this.props.configLoaded) {
        if (ChatSession.checkSession()) {
          console.debug("Já tinha sessao aberta!");

          const lastActivity = localStorage.getItem("last-activity");
          const chatTimeoutMs = this.props.config.chatTimeoutMs || 300000;
          if (lastActivity && Date.now() - lastActivity * 1 < chatTimeoutMs) {
            this.props.toggleChat();
            this.props.toggleWebsocket();
            this.props.initConversation();
          } else {
            clearLocalStorage()
          }
        }
        clearInterval(interval);
      }
    }, 100);
  }

  render() {
    const isMobile = this.userInfo.isMobile;
    if (!this.props.configLoaded) return null;
    return (
      <div className={`chat-web-container theme--${this.props.theme}`}>
        {this.props.opened ? (
          <Suspense fallback={() => {}}>
            <Chat />
          </Suspense>
        ) : isMobile ? (
          <ChatButtonMobile />
        ) : (
          <ChatButtonDesktop />
        )}
        <Modal config={this.props.config} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  configLoaded: state.app.configLoaded,
  opened: state.app.opened,
  messages: state.messages,
  config: state.config,
  theme: state.theme
});

const mapDispatchToProps = (dispatch) => ({
  loadConfig: () => dispatch(loadConfig()),
  toggleChat: () => dispatch(toggleChat(true)),
  toggleWebsocket: () => dispatch(toggleWebsocket(true)),
  initConversation: (config) => dispatch(initConversation(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
