import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import  ReactToPrint from "react-to-print";

class ButtonPrint extends PureComponent {

  state = {
    disabled: true,
  }

  constructor(props) {
    super(props);
    this.enablePrinChat = this.enablePrinChat.bind(this);
  }

  handleClick = (type, e) => {
    this.setState({
      ...this.state,
      disabled: false,
    })
  }

  printChat () {
    const componentRef = document.getElementById("chat-list-messages");
    return (
      <div className="quick-reply-container-3">
        <ReactToPrint
          bodyClass={"message-item"}
          trigger={() =>
            <button className="quick-replies eds-style-text hide-print">
              <FontAwesomeIcon
                style={{marginRight: "5px"}}
                icon={faPrint}
                size="sm"
                className="chat-icon"
              />
              Imprimir Conversa
            </button>}
          content={() => componentRef}
        />
      </div>
    )
  }

  enablePrinChat () {
    if (!this.props.config.isEnablePrintChat) return false;
    return this.props.config.isEnablePrintChat;

  }
  render() {
    if (!this.enablePrinChat()) return false;
    return this.printChat();
  }
}

export default ButtonPrint;